function toggleMenu() {


    let is_menu_open = $("#menu__overlay").is(":visible");

    let easing_in = "easeOutBack";
    let easing_out = "easeInOutBack";
    let time = 750;

    if (!is_menu_open) {
        $("#menu__overlay").slideDown({
            duration: time,
            easing: easing_in,
        });

        $(".hamburger__menu").css("background-color", "transparent");
    } else {
        $("#menu__overlay").slideUp({
            duration: time,
            easing: easing_out,

        });

        $(".hamburger__menu").css("background-color", "rgba(0, 0, 0, 0.35)");
    }
}

function toggleBlogMenu() {

    let is_menu_open = $("#blog__article__text__container").hasClass("open");

    if (is_menu_open) {
        $("#blog__article__text__container").removeClass("open");
        $("#btn_blog").html("<img src=\"/assets/images/show.svg\"> mostra opzioni").css("background", "#fec135");
        $("#btn_blog2").html("<img src=\"/assets/images/show.svg\"> mostra opzioni").css("background", "#fec135");
    } else {
        $("#blog__article__text__container").addClass("open");
        $("#btn_blog").html("<img src=\"/assets/images/hide.svg\"> nascondi opzioni").css("background", "#f67d27");
        $("#btn_blog2").html("<img src=\"/assets/images/hide.svg\"> nascondi opzioni").css("background", "#f67d27");
    }

    // alert(is_menu_open);
}

function toggleOverlay() {
    let is_menu_open = $("#overlay").is(":visible");

    let easing_in = "easeOutBack";
    let easing_out = "easeInOutBack";
    let time = 750;

    if (!is_menu_open) {
        $("#overlay").css("display", "flex");

    } else {
        $("#overlay").css("display", "none");

    }
}


function menu_click(num_delay) {
    var i = 0;

    $('.mobile-menu').on('click', function () {
        i++;

        let easing_in = "easeOutBack";
        let easing_out = "easeInOutBack";
        let time = 750;

        if (i == 0 || i % 2 == 0) {
            console.log('Il menu è chiuso');
            $(this).removeClass('open');
            $('.background-box').removeClass('open');
            $(this).delay(num_delay).queue(function (next) {
                $(this).removeClass('opening');
                next();
            });

            $("#menu__overlay").slideUp({
                duration: time,
                easing: easing_out,

            });

            $(".hamburger__menu").css("background-color", "rgba(0, 0, 0, 0.35)");

            $("body").removeClass("modal-open");

        } else {
            console.log('Il menu è aperto');
            $(this).addClass('opening');
            $('.background-box').addClass('open');
            $(this).delay(num_delay).queue(function (next) {
                $(this).addClass('open');
                next();
            });

            $("#menu__overlay").slideDown({
                duration: time,
                easing: easing_in,
            });

            $(".hamburger__menu").css("background-color", "transparent");
            $("body").addClass("modal-open");
        }
    });
}

$(document).ready(function () {
    menu_click(500);

    $(".custom__multiselect__submenu span").click(function () {
        let el = $(this);

        if (!el.hasClass("selected")) {
            el.addClass("selected");
        } else {
            el.removeClass("selected");
        }

        let html = "";
        $(".custom__multiselect__submenu .selected").each(function () {
            let name = $(this).attr("data-name");
            html += '<span class="tag" data-name="' + name + '" onclick="removeTag(this)"><i class=\'x\'>x</i>' + name + '</span>';
        });

        $("#tags__container").html(html);
    });
    //
    // $(".custom__multiselect__submenu .x").click(function(){
    // 	let el = $(this).closest("span");
    //
    // 	el.removeClass("selected");
    // });

    $("#selectTipoSerata div").click(function () {


        if ($(this).attr("data-selected") == "1") {
            $(this).attr("data-selected", 0);
        } else {
            $(this).closest(".multiple__selects").find("div").attr("data-selected", 0);
            $(this).attr("data-selected", 1);

        }


    });

    $("#selectGenere div").click(function () {

        if ($(this).attr("data-selected") == "1") {
            $(this).attr("data-selected", 0);
        } else {
            // $(this).closest(".multiple__selects").find("div").attr("data-selected", 0);
            $(this).attr("data-selected", 1);

        }

    });

    $("#selectDresscode div").click(function () {

        if ($(this).attr("data-selected") == "1") {
            $(this).attr("data-selected", 0);
        } else {
            $(this).closest(".multiple__selects").find("div").attr("data-selected", 0);
            $(this).attr("data-selected", 1);

        }

    });

    $('#overlay').mousedown(function (e) {
        var clicked = $(e.target);
        if (clicked.is('.overlay__box') || clicked.parents().is('.overlay__box')) {
            return;
        } else {
            // $('#overlay').hide();
            toggleOverlay();
        }
    });
});

function removeTag(obj) {
    let o = $(obj)

    $(".custom__multiselect span[data-name='" + o.attr("data-name") + "']").removeClass("selected");
    o.remove();
}

function uploaded(id) {
    let el = document.getElementById(id);

    if (el.getAttribute("data-uploaded") != 1) {
        el.value = "file-noname-01.pdf";
        el.setAttribute("data-uploaded", 1);
    } else {
        el.value = "Allega un file +";
        el.setAttribute("data-uploaded", 0);
    }

}

function eulaClicked() {
    let checked = $(".eula").attr("data-checked");

    if (checked == 1) {
        $(".eula img").attr("src", "/assets/images/circle.svg");
        $(".eula button").removeClass("btn--blue").addClass("btn--disabled");
        $(".eula").attr("data-checked", 0);
    } else {
        $(".eula img").attr("src", "/assets/images/check-circle.svg");
        $(".eula").attr("data-checked", 1);
        $(".eula button").removeClass("btn--disabled").addClass("btn--blue");
    }
}

function checkDate(obj) {
    let o = $(obj);

    if (!isValidDate(o.val())) {
        alert("Data non valida");
    } else {

    }
}

function isValidDate(dateString) {

    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) return false;  // Invalid format
    var d = new Date(dateString);
    var dNum = d.getTime();
    if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
    return d.toISOString().slice(0, 10) === dateString;
}

$(window).scroll(function () {
    var height = $(window).scrollTop();

    if (height > 400) {
        $("#hamburger-bg").addClass("w--bg");
    } else {
        $("#hamburger-bg").removeClass("w--bg");
    }
});

function goAlt() {
    for (let i = 1; i <= 4; i++) {
        $("#tab-" + i).css({
            // display: "block",
            width: "100%",
        }).insertAfter($(".tab[data-n='" + i + "']"));
    }

    $(".tabs__content").hide();
    $(".tabs .tab").removeAttr("data-current");
    standard_mode = false;

}

function goStandard() {
    for (let i = 1; i <= 4; i++) {
        $("#tab-" + i).css({
            // display: "block",
            width: "100%",
        }).insertAfter($(".tabs"));
    }

    $(".tabs__content").hide();
    $(".tabs .tab").removeAttr("data-current");
    $(".tab[data-n='1']").attr("data-current", 1);
    $("#tab-1").show();
    standard_mode = true;
}